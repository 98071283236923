@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "variables";
@import "typography";

.rich-text {
  &__heading-2,
  &__heading-3,
  &__heading-4,
  &__heading-5,
  &__heading-6 {
    font-family: $font;
    font-weight: 600;
    text-transform: none;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
  }

  &__heading-2 {
    font-size: 1.425rem;
  }

  &__heading-3 {
    font-size: 1.2rem;
  }

  &__heading-4 {
    font-size: 1rem;
  }

  &__heading-5 {
    font-size: .9rem;
  }

  &__heading-6 {
    font-size: .8rem;
  }

  &__paragraph {
    font-size: 1rem;
    color: $color-dark-grey;
    padding-bottom: 1.5rem;
    margin-bottom: 0;

    @include media-breakpoint-up("lg") {
      font-size: 1.125rem;
    }
  }

  &__image {
    display: block;
    width: 100%;
    padding-bottom: 1.5rem;
  }

  &__ordered-list,
  &__unordered-list {
    padding-bottom: 1.5rem;
    margin-bottom: 0;
  }

  &__list-item {
    font-family: $font;
    font-size: 1.125rem;
    color: $color-dark-grey;
  }
}
