@import "src/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

@font-face {
  font-family: $font;
  src: url('../assets/fonts/jcber-webfont.eot') format('eot');
  src: url('../assets/fonts/jcber-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/jcber-webfont.woff') format('woff'),
  url('../assets/fonts/jcber-webfont.ttf') format('truetype'),
  url('../assets/fonts/jcber-webfont.svg#JCBEuroRomanRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font;
  src: url('../assets/fonts/jcbeb-webfont.eot') format('eot');
  src: url('../assets/fonts/jcbeb-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/jcbeb-webfont.woff') format('woff'),
  url('../assets/fonts/jcbeb-webfont.ttf') format('truetype'),
  url('../assets/fonts/jcbeb-webfont.svg#JCBEuroBoldRegular') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $font;
  //src: url('assets/fonts/jcbei-webfont.eot') format('eot');
  src: url('../assets/fonts/jcbei-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/jcbei-webfont.woff') format('woff'),
  url('../assets/fonts/jcbei-webfont.ttf') format('truetype'),
  url('../assets/fonts/jcbei-webfont.svg#JCBEuroItalicRegular') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: $font-condensed;
  //src: url('assets/fonts/jcbebc__-webfont.eot') format('eot');
  src: url('../assets/fonts/jcbebc__-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/jcbebc__-webfont.woff') format('woff'),
  url('../assets/fonts/jcbebc__-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
}

.btn {
  text-transform: uppercase;
}

[class^="display-"] {
  line-height: normal;
  font-family: $font-condensed;
  font-weight: normal;
  text-transform: uppercase;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  text-transform: uppercase;
  font-weight: normal;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: $font-condensed;
}

.text-large {
  font-size: 22px;

  @include media-breakpoint-up("lg") {
    font-size: 32px;
  }
}

.text-small {
  font-size: 14px;
}

.lead {
  font-size: 16px;

  @include media-breakpoint-up("lg") {
    font-size: 18px;
  }
}

@include media-breakpoint-down("lg") {
  .display-2 {
    font-size: 40px;
  }

  .display-3 {
    font-size: 30px;
  }

  .display-4 {
    font-family: $font;
    font-size: 22px;
    font-weight: bold;
  }

  h1, .h1 {
    font-size: 30px;
  }

  h2, .h2 {
    font-size: 22px;
  }

  h3, .h3 {
    font-size: 22px;
  }
}
