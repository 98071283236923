@import "variables";
@import "bootstrap/scss/bootstrap";

@import "styles/typography";
@import "styles/buttons";
@import "styles/button-dropdown";
@import "styles/rich-text";

html,
body {
  color: $black;
  background-color: $white;
  font-family: $font, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-smoothing: antialiased;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.bg-mid-light {
  background-color: $mid-light;
}

.cursor-pointer {
  cursor: pointer;
}

.testimony-circle {
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background: $mid-light no-repeat center center / cover;
}

.fade-animated {
  transition: opacity .3s ease;
}

.faded {
  opacity: .4 !important;
}

.aspect-ratio-16-9 {
  height: 0;
  width: 100%;
  padding-top: 56.25%;
}

.icon {
  display: block;
  height: 25px;
  width: 50px;
  background: no-repeat center left / contain;

  @include media-breakpoint-up("lg") {
    height: 40px;
    width: 80px;
  }
}

.container {
  padding: 0 2rem;

  @include media-breakpoint-up("md") {
    padding: 0 3rem;
  }
}

.narrow {
  max-width: 1140px;
}

.link {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none !important;
}

.text-underline {
  text-decoration: underline !important;
}
