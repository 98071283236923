// Bootstrap override
$white: white;
$primary: #fcb026;
$light: #f8f8f6;
$text-muted: #888;
$secondary: $text-muted;
$dark: #333;
$mid-light: #e6e6e6;
$black: black;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1280px,
  xl: 1420px
);

$progress-height: 2px;
$progress-bar-bg: $primary;
$progress-bg: $mid-light;

$btn-padding-y-lg: 16px;
$btn-font-size: 16px;
$btn-font-size-lg: 18px;
$lead-font-size: 1.3rem;
$display2-size: 70px;
$display3-size: 50px;
$display4-size: 2.8rem;
$h1-font-size: 26px;
$h2-font-size: 26px;

// Custom
$font: 'JCBEuro';
$font-condensed: 'JCBEuroCondensed';

$header-height: 102px;
$header-height-small: 86px;

$color-dark-grey: #424242;
$color-shark: #2C2E33;
$color-silver: #9F9F9F;
$color-jcb: #FCB026;
