@import "src/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.btn {
  padding: 12px 25px;
  border-radius: 0;
  line-height: 19px;
  font-weight: bold;
}

.btn-lg {
  width: 220px;
  padding: 16px 25px;
  line-height: 22px;
}

.btn-alt {
  padding: 18px 44px;
  line-height: 26px;
  font-family: $font-condensed;
  font-size: 22px;
  font-weight: normal;
}

.btn-black {
  color: $white;
  background-color: $black;

  &:focus,
  &:hover {
    color: $white;
    background-color: lighten($black, 20%);
  }

  &:active  {
    color: $white;
    background-color: lighten($black, 30%);
  }
}


.btn-white {
  color: $black;
  background-color: $white;

  &:focus,
  &:hover {
    color: $black;
    background-color: darken($white, 10%);
  }

  &:active  {
    color: $black;
    background-color: darken($white, 20%);
  }
}
