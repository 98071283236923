@import "src/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

@keyframes dropdown-fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px) translateX(-160px);
  }

  to {
    opacity: 1;
    transform: translateY(0) translateX(-160px);
  }
}

.btn-dropdown {
  position: relative;

  .btn-dropdown-content {
    display: block;
    position: absolute;
    top: 72px;
    left: 50%;
    z-index: 20;
    width: 320px;
    padding: 1.5rem;
    border-radius: $border-radius;
    transition: top .3s ease;
    transform: translateX(-160px);
    animation: dropdown-fade-in .2s ease-out;
    box-shadow: 0 3px 8px rgba(black, .05), 0 8px 18px rgba(black, .05);
    background-color: $white;

    header.scrolled & {
      top: 65px;
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      bottom: 100%;
      left: calc(50% - 8px);
      border: 8px solid transparent;
      border-bottom-color: $white;
      pointer-events: none;
    }
  }
}
